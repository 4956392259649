<template>
    <div>
        <div class="card card-custom mt-5">
            <div class="card-body">
                <!-- Filter -->
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10">
                                <table class="table table-bordered">

                                    <tbody>

                                    <tr v-if="$can('report_sales.report_customer_transactions')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.customer_transactions_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'customer-transactions-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="$can('report_sales.sales_via_interval')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.sales_via_interval_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'sales-via-interval-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <h4>{{$t('sales_segmented_reports')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.sales_segmented_periodic')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.sales_by_client_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'summary', 'filter':'customer'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'details', 'filter':'customer'}}" target="_blank">
                                                     | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr  v-if="$can('report_sales.sales_segmented_periodic')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.sales_by_staff_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'summary', 'filter':'employee'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'details', 'filter':'employee'}}" target="_blank">
                                                     | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
<!--                                    <tr v-if="$can('report_sales.sales_segmented_periodic')">-->
<!--                                        <td class="d-flex justify-content-between">-->
<!--                                            <span>{{$t('MENU.sales_by_sales_person')}}</span>-->
<!--                                            <div>-->
<!--                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'summary', 'filter':'sales_man'}}" target="_blank">-->
<!--                                                    {{$t('summary')}}-->
<!--                                                </router-link>-->
<!--                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'details', 'filter':'sales_man'}}" target="_blank">-->
<!--                                                    | {{$t('details')}}-->
<!--                                                </router-link>-->

<!--                                            </div>-->
<!--                                        </td>-->
<!--                                    </tr>-->
                                    <tr>
                                        <td>
                                            <h4>{{$t('sales_periodic_reports')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.sales_segmented_periodic')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('daily_sales')}}</span>
                                            <div>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'summary', 'filter':'daily'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'details', 'filter':'daily'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.sales_segmented_periodic')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('weekly_sales')}}</span>
                                            <div>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'summary', 'filter':'weekly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'details', 'filter':'weekly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.sales_segmented_periodic')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('monthly_sales')}}</span>
                                            <div>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'summary', 'filter':'monthly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'details', 'filter':'monthly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.sales_segmented_periodic')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('yearly_sales')}}</span>
                                            <div>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'summary', 'filter':'yearly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'sales-reports.list', query:{tab: 'details', 'filter':'yearly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h4>{{$t('payments_segmented_reports')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.report_payments_sales')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('payments_sales_by_client_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'summary', 'filter':'customer'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'details', 'filter':'customer'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.report_payments_sales')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('payments_sales_by_staff_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'summary', 'filter':'employee'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'details', 'filter':'employee'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.report_payments_sales')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('payments_sales_by_payment_method_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'summary', 'filter':'payment_method'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'details', 'filter':'payment_method'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <h4>{{$t('payments_periodic_reports')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.report_payments_sales')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('payments_sales_by_daily_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'summary', 'filter':'daily'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'details', 'filter':'daily'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.report_payments_sales')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('payments_sales_by_weekly_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'summary', 'filter':'weekly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'details', 'filter':'weekly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.report_payments_sales')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('payments_sales_by_monthly_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'summary', 'filter':'monthly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'details', 'filter':'monthly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_sales.report_payments_sales')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('payments_sales_by_yearly_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'summary', 'filter':'yearly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-sales-reports.list', query:{tab: 'details', 'filter':'yearly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

    export default {
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_reports")}]);

        },
    }
</script>
<style scoped>
    td {
        padding: 1rem;
    }
</style>